import { RowDoubleClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';
import { compareDatesForAgGridFilter, formatDateForAgGrid } from '../../../../../helpers/AgGridDateHelpers';
import PermissionType from '../../../../../domain/permission';
import { doActionsContain, hasPermission } from '../../../../../helpers/PermissionHelper';
import {
  CORE_RECURRING_GOAL_SCHEDULES_URL,
  CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
} from '../../../../../constants/urls';
import {
  getDefaultColDef,
  defaultOnGridReady,
  getDefaultUseQueryConfig,
  useGridComponentStates,
  getDefaultStatusBar,
} from '../../../../../helpers/GridComponentHelpers';
import GenericDeleteDialog from '../../../../GenericDeleteDialog/GenericDeleteDialog';
import CoreRecurringGoalScheduleType from '../../../../../domain/coreRecurringGoalSchedule';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../../FeedbackMessages/FeedbackMessages';
import GenericGridActions from '../../../../GenericGridActions/GenericGridActions';
import ModifyCoreRecurringGoalScheduleDialog, {
  defaultHandleModifyCoreRecurringGoalScheduleDialogOk,
} from '../ModifyCoreRecurringGoalScheduleDialog/ModifyCoreRecurringGoalScheduleDialog';
import { HQ_TIMEZONE } from '../../../../../constants/globals';
import GoalGridSummary from '../../../../goals/GoalGridSummary/GoalGridSummary';

type CoreRecurringGoalScheduleGridProps = {
  fieldCoreProcess: FieldCoreProcessType;
  idToFocusOn?: number;
};

const getColumns = (openModifyDialog: (objectToModify: CoreRecurringGoalScheduleType) => void, hqTimezone: string) => {
  return [
    {
      field: 'summaryTemplate',
      headerName: 'Summary',
      tooltipField: 'templateTaskToCreate.descriptionTemplate',
      // Use flex instead of width to fill up the rest of the space
      // width: null,
      flex: 1,
      cellRenderer: 'summaryRenderer',
    },
    {
      field: 'frequency',
      headerName: 'Freq.',
      width: 90,
    },
    {
      field: 'nextDueDateTime',
      headerName: 'Next Due',
      width: 110,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return '';
        }
        return formatDateForAgGrid(params.value, hqTimezone);
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          return compareDatesForAgGridFilter(filterLocalDateAtMidnight, cellValue, hqTimezone);
        },
      },
    },
    {
      field: 'suggestedStaff',
      headerName: 'Suggested Staff',
      width: 150,
    },
    {
      field: 'totalPoints',
      headerName: 'Weight',
      width: 90,
    },
    {
      field: 'onNcr',
      headerName: 'NCR?',
      width: 50,
      cellRenderer: (params: any) => {
        return params.value ? <span>✅</span> : <span></span>;
      }
    },
    {
      field: 'onSmr',
      headerName: 'SMR?',
      width: 50,
      cellRenderer: (params: any) => {
        return params.value ? <span>✅</span> : <span></span>;
      }
    },
    {
      headerName: 'Actions',
      width: 90,
      cellRenderer: 'actionsRenderer',
      cellRendererParams: {
        onEditClick: (scheduleToEdit: CoreRecurringGoalScheduleType) => {
          openModifyDialog(scheduleToEdit);
        },
      },
    },
  ];
};

const CoreRecurringGoalScheduleGrid = (props: CoreRecurringGoalScheduleGridProps) => {
  let gridComponentStates = useGridComponentStates<CoreRecurringGoalScheduleType>();
  const [columnDefs] = React.useState(getColumns(gridComponentStates.openModifyDialog, HQ_TIMEZONE));

  const coreRecurringGoalSchedulesRequestConfig = {
    params: {
      field_core_process: props.fieldCoreProcess.id,
    },
  } as AxiosRequestConfig;

  const {
    isRefetching: isRefetchingSchedules,
    error: schedulesError,
    data: schedulesData,
    refetch: refetchSchedules,
  } = useQuery(
    ['getCoreRecurringGoalSchedulesForFcp', coreRecurringGoalSchedulesRequestConfig],
    () => {
      // If the grid isn't ready, this won't work,
      //  but ag grid defaults to showing loading overlay initially anyway
      if (gridComponentStates.gridApi) {
        gridComponentStates.gridApi.showLoadingOverlay();
      }
      return axios.get<CoreRecurringGoalScheduleType[]>(
        CORE_RECURRING_GOAL_SCHEDULES_URL,
        coreRecurringGoalSchedulesRequestConfig,
      );
    },
    getDefaultUseQueryConfig<CoreRecurringGoalScheduleType>(
      gridComponentStates.gridApi,
      gridComponentStates.openModifyDialog,
      props.idToFocusOn,
    ),
  );

  const getCoreRecurringGoalSchedulePermissionsRequestConfig = {
    params: {
      // No parameters
    },
  } as AxiosRequestConfig;
  const {
    error: schedulePermissionsError,
    data: schedulePermissionsData,
    isLoading: isPermissionsLoading,
  } = useQuery(
    ['getCoreRecurringGoalSchedulePermissions', getCoreRecurringGoalSchedulePermissionsRequestConfig],
    () => {
      return axios.get<PermissionType[]>(
        CORE_RECURRING_GOAL_SCHEDULE_PERMISSIONS_URL,
        getCoreRecurringGoalSchedulePermissionsRequestConfig,
      );
    },
  );

  if (schedulesError || schedulePermissionsError) return <p>Error!</p>;

  const coreRecurringGoalSchedules = schedulesData?.data;
  const coreRecurringGoalSchedulePermissions = schedulePermissionsData?.data;

  if (
    !isPermissionsLoading &&
    (!coreRecurringGoalSchedulePermissions || coreRecurringGoalSchedulePermissions.length === 0)
  ) {
    return <p>No permission for core recurring goal schedules.</p>;
  }

  const handleModifyDialogDeleteIcon = (updatedSchedule: CoreRecurringGoalScheduleType) => {
    gridComponentStates.closeModifyDialog();
    gridComponentStates.openDeleteDialog(updatedSchedule);
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent) => {
    const schedule = event.data as CoreRecurringGoalScheduleType;
    if (doActionsContain(schedule, 'change')) {
      gridComponentStates.openModifyDialog(schedule);
    }
  };

  const onCreateClick = () => {
    // Null for new object
    gridComponentStates.openModifyDialog(null);
  };

  return (
    <Container
      fluid
      className={
        //styles.container +
        ' d-flex flex-column flex-grow-1'
      }
    >
      <Row className="d-flex flex-column flex-grow-1">
        <Col>
          <div
            className="ag-theme-balham"
            style={{
              height: '200px',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={(params) => {
                defaultOnGridReady(
                  params,
                  coreRecurringGoalSchedules,
                  isRefetchingSchedules,
                  gridComponentStates.setGridApi,
                );
              }}
              frameworkComponents={{
                actionsRenderer: GenericGridActions,
                // tagsRenderer: TaskGridTags,
                // dateAndCreationIconRenderer: TaskGridDateAndCreationIcon,
                summaryRenderer: GoalGridSummary,
              }}
              onRowDoubleClicked={handleRowDoubleClick}
              columnDefs={columnDefs}
              // 2022-18-07 Kirk, Austin, Brad - filter is too buried, make the button always visible
              suppressMenuHide={true}
              tooltipShowDelay={450}
              defaultColDef={getDefaultColDef()}
              // Normally we pass in row data via api on react-query onSuccess instead.
              // Otherwise this comes through as undefined on user tab switching which breaks loading spinner
              // However, apparently onSuccess doesn't get called when the data was cached
              // That means when you flip Legal to Station Expansion and back to Legal, Legal Cases don't show up
              // Using rowData fixes this, but I'm hoping for a better solution later.
              rowData={coreRecurringGoalSchedules}
              statusBar={getDefaultStatusBar()}
            ></AgGridReact>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {coreRecurringGoalSchedulePermissions &&
            hasPermission(coreRecurringGoalSchedulePermissions, 'add_corerecurringgoalschedule') && (
              <Button variant="primary" onClick={onCreateClick}>
                Create a core goal <i className="bi bi-plus" />
              </Button>
            )}
        </Col>
      </Row>
      <GenericDeleteDialog
        state={gridComponentStates.deleteDialogState}
        onCancel={gridComponentStates.closeDeleteDialog}
        onOk={(idToDelete) =>
          handleDeleteCoreRecurringGoalScheduleDialogOk(
            idToDelete,
            gridComponentStates.closeDeleteDialog,
            gridComponentStates.disableSubmissionDeleteDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        objectTypeString="core recurring goal schedule"
      />
      <ModifyCoreRecurringGoalScheduleDialog
        // Should I change how I pass this state somehow?
        state={gridComponentStates.modifyDialogState}
        onCancel={gridComponentStates.closeModifyDialog}
        onOk={(modifySchedule, idToUpdate) =>
          defaultHandleModifyCoreRecurringGoalScheduleDialogOk(
            modifySchedule,
            idToUpdate,
            gridComponentStates.closeModifyDialog,
            refetchSchedules,
            gridComponentStates.addFeedbackMessage,
          )
        }
        onDeleteIcon={handleModifyDialogDeleteIcon}
        fieldCoreProcess={props.fieldCoreProcess}
      />
    </Container>
  );
};

// TODO should I make this generic, too?
export function handleDeleteCoreRecurringGoalScheduleDialogOk(
  idToDelete: number,
  closeDeleteDialog: () => void,
  disableSubmissionDeleteDialog: () => void,
  refetch: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  disableSubmissionDeleteDialog();
  return axios.delete(CORE_RECURRING_GOAL_SCHEDULES_URL + '/' + idToDelete + '/').then((response) => {
    closeDeleteDialog();
    addFeedbackMessage({
      key: createFeedbackMessageKey('recurringGoalSchedule', 'delete', idToDelete),
      status: 'success',
      messageBody: <span>Recurring goal schedule deleted successfully.</span>,
    });
    refetch();
  });
}

export default CoreRecurringGoalScheduleGrid;
