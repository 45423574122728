export const HQ_TIMEZONE = 'UTC';
export const TEAM_MEMBER_SEARCH_PARAM = 'teamMember';
export const GET_MEMBER_SCORE_SUMMARY = 'getMemberScoreSummary';
export const GET_TEAM_SCORE_SUMMARY_FOR_TEAM = 'getTeamScoreSummaryForTeam';
export const CATEGORY = 'CATEGORY';
export const AT_DEPT = 'Anti-Trafficking'
export const FH_DEPT = 'Family Homes'
export const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

export const PLANNING_TO_AUTO_OPTIONS = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'Maybe',
    value: 'MAYBE',
  },
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
];

export const DEADLINE_FLEXIBILITY_OPTIONS = [
  {
    label: 'Important',
    value: 'IMPORTANT',
  },
  {
    label: 'Preferred',
    value: 'PREFERRED',
  },
  {
    label: 'Flexible',
    value: 'FLEXIBLE',
  },
]