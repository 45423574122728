import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import CoreRecurringGoalScheduleType, {
  convertToTypeToSend,
  CoreRecurringGoalScheduleTypeToSend,
} from '../../../../../domain/coreRecurringGoalSchedule';
import { CORE_RECURRING_GOAL_SCHEDULES_URL, GOAL_SOURCE_FIELDS_URL } from '../../../../../constants/urls';
import FormikController from '../../../../../formik/FormikSelect/FormikController';
import { Mode } from '../../../../../state_types/mode';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../../FeedbackMessages/FeedbackMessages';
import { getNameOfCreationUser } from '../../../../../helpers/AuditFieldHelper';
import React from 'react';
import { getSimpleModal, ModifyDialogState } from '../../../../../helpers/GridComponentHelpers';
import {
  FREQUENCY_OPTIONS,
  HQ_TIMEZONE,
  PLANNING_TO_AUTO_OPTIONS,
} from '../../../../../constants/globals';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';
import GoalSourceFieldType from '../../../../../domain/goalSourceField';
import { useQuery } from 'react-query';
import { SingleValue } from 'react-select';
import { GenericOption } from '../../../../../formik/FormikSelect/FormikSelect';
import { getObjectFromListById } from '../../../../../helpers/ObjectIdHelpers';
import { getFormikReadOnlyField } from '../../../../../formik/FormikHelpers';
import {
  getBetterDirection,
  getUnitOfMeasure,
  getUnitOfMeasureElement,
  getUnitOfMeasureLabel,
} from '../../../../goals/recurring/ModifyRecurringGoalScheduleDialog/ModifyRecurringGoalScheduleDialog';
import { getDisplayValue } from '../../../../../domain/recurringGoalSchedule';
import styles from './ModifyCoreRecurringGoalScheduleDialog.module.scss';
import GoalScoringVisualLineGraph
  from '../../../../goals/recurring/GoalScoringVisualLineGraph/GoalScoringVisualLineGraph';
import { useGetFpcs } from '../../../apis/fcp_api';

export interface ModifyCoreRecurringGoalScheduleDialogProps {
  fieldCoreProcess?: FieldCoreProcessType;
  state: ModifyDialogState<CoreRecurringGoalScheduleType>;
  onOk: (updatedScheduleFields: CoreRecurringGoalScheduleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (scheduleToDelete: CoreRecurringGoalScheduleType) => void;
}

const getMode = (state: ModifyDialogState<CoreRecurringGoalScheduleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Core Recurring Goal Schedule';
};
const PERCENT_UOM = 'percent';

const LINEAR_FROM_CUTOFF = 'LINEAR_FROM_CUTOFF';
const ALL_OR_NOTHING = 'ALL_OR_NOTHING';
const SCORING_TYPE_OPTIONS = [
  {
    label: 'Partial Credit',
    value: LINEAR_FROM_CUTOFF,
  },
  {
    label: 'All or nothing',
    value: ALL_OR_NOTHING,
  },
  // {
  //   label: 'Buckets',
  //   value: 'BUCKETS',
  // }
];

const ModifyCoreRecurringGoalScheduleDialog = (props: ModifyCoreRecurringGoalScheduleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };

  const requestConfig = {
    params: {
      // No params
    },
  } as AxiosRequestConfig;
  const { isLoading, error, data } = useQuery(['getAllSourceFields', requestConfig], () => {
    return axios.get<GoalSourceFieldType[]>(GOAL_SOURCE_FIELDS_URL, requestConfig);
  });
  const initialFcp = props.fieldCoreProcess;
  const { isLoading: isLoadingFpcs, error: fcpError, data: fcpData } = useGetFpcs(null);
  if (isLoading || isLoadingFpcs) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  }
  if (error || fcpError) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  }
  if (data === undefined) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data');
  }

  const sourceFields = data.data;

  const sourceFieldOptions = sourceFields.map((sourceField) => {
    const optionLabel = sourceField.name + ' - ' + sourceField.source.name;
    return {
      label: optionLabel,
      value: sourceField.id,
    };
  });

  let fieldCoreProcessOptions: any[] = [];
  if (fcpData) {
    const fieldCoreProcesses = fcpData.data as FieldCoreProcessType[];
    fieldCoreProcessOptions = fieldCoreProcesses.map((fieldCoreProcess) => {
      const optionLabel = fieldCoreProcess.name;
      return {
        label: optionLabel,
        value: fieldCoreProcess.id,
      };
    });
  }

  // TODO should this be in some sort of useState hook?
  var initialStateOfEditableScheduleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const schedule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableScheduleFields = {
      // Not editable
      // id: task.id,
      frequency: schedule.frequency,
      startDateTime: schedule.startDateTime,

      summaryTemplate: schedule.summaryTemplate,
      linkUrlTemplate: schedule.linkUrlTemplate,
      descriptionTemplate: schedule.descriptionTemplate,
      suggestedStaff: schedule.suggestedStaff,

      scoringType: schedule.scoringType,
      totalPoints: schedule.totalPoints,
      onNcr: schedule.onNcr,
      onSmr: schedule.onSmr,
      planningToAuto: schedule.planningToAuto,
      sourceField: schedule.sourceField,
      goalValue: getDisplayValue(schedule.goalValue, schedule.unitOfMeasure),
      cutoffValue: getDisplayValue(schedule.cutoffValue, schedule.unitOfMeasure),

      allowAutoPush: schedule.allowAutoPush,
      autoPushExceedByDeltaValue: getDisplayValue(schedule.autoPushExceedByDeltaValue, schedule.unitOfMeasure),
      autoPushChangeDeltaValue: getDisplayValue(schedule.autoPushChangeDeltaValue, schedule.unitOfMeasure),
      autoPushBestExpectedValue: getDisplayValue(schedule.autoPushBestExpectedValue, schedule.unitOfMeasure),

      fieldCoreProcess: schedule.fieldCoreProcess.id,
    } as CoreRecurringGoalScheduleTypeToSend;
    const createdByName = getNameOfCreationUser(schedule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className='bi-trash-fill mr-auto'
        variant='danger'
        onClick={() => {
          onDeleteIcon(schedule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedSchedule: CoreRecurringGoalScheduleTypeToSend, { setSubmitting }: any) => {
      console.log('update core goal schedule dialog ok hit', updatedSchedule);
      const sourceField = getObjectFromListById(sourceFields, updatedSchedule.sourceField);
      const unitOfMeasure = sourceField?.unitOfMeasure;
      // TODO this is hacky
      updatedSchedule._unitOfMeasure = unitOfMeasure;
      setTimeout(() => {
        // TODO make this typescript cast have a validation or something
        onOk(updatedSchedule, schedule.id).then(() => {
          // In case new tags were created
          // refetch();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableScheduleFields = {
      frequency: '',
      startDateTime: '',

      summaryTemplate: '',
      linkUrlTemplate: '',
      descriptionTemplate: '',
      suggestedStaff: '',

      scoringType: '',
      totalPoints: undefined,
      onNcr: false,
      onSmr: false,
      planningToAuto: 'UNKNOWN',
      sourceField: undefined,
      goalValue: undefined,
      cutoffValue: undefined,

      allowAutoPush: false,
      autoPushExceedByDeltaValue: undefined,
      autoPushChangeDeltaValue: undefined,

      fieldCoreProcess: initialFcp?.id,
    } as CoreRecurringGoalScheduleTypeToSend;
    onSubmit = (newSchedule: CoreRecurringGoalScheduleTypeToSend, { setSubmitting }: any) => {
      console.log('create core goal schedule dialog ok hit', newSchedule);
      const sourceField = getObjectFromListById(sourceFields, newSchedule.sourceField);
      const unitOfMeasure = sourceField?.unitOfMeasure;
      // TODO this is hacky
      newSchedule._unitOfMeasure = unitOfMeasure;
      setTimeout(() => {
        // TODO make this typescript cast have a validation or something
        onOk(newSchedule, null).then(() => {
          // In case new tags were created
          // refetch();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const RecurringGoalScheduleFormSchema = Yup.object().shape({
    frequency: Yup.string().nullable().required('Required'),
    startDateTime: Yup.date().required('Required'),

    summaryTemplate: Yup.string().required('Required'),
    linkUrlTemplate: Yup.string().optional(),
    descriptionTemplate: Yup.string().optional(),
    scoringType: Yup.string().nullable().required('Required'), // TODO make enum
    totalPoints: Yup.number().required('Required'), // TODO make integer only
    // sourceField: Yup.number().nullable().required('Required'),
    sourceField: Yup.number().nullable().optional(), // Temporarily optional until we have a better idea what these will be
    goalValue: Yup.number().required('Required'), // TODO make decimal or integer
    cutoffValue: Yup.number().when('scoringType', {
      is: LINEAR_FROM_CUTOFF,
      then: Yup.number().required('Required'), // TODO make decimal or integer
      otherwise: Yup.number().optional(),
    }),
    allowAutoPush: Yup.boolean().required('Required'),
    autoPushExceedByDeltaValue: Yup.number().when('allowAutoPush', {
      is: true,
      then: Yup.number().required('Required'), // TODO make decimal or integer
      otherwise: Yup.number().optional(),
    }),
    autoPushChangeDeltaValue: Yup.number().when('allowAutoPush', {
      is: true,
      then: Yup.number().required('Required'), // TODO make decimal or integer
      otherwise: Yup.number().optional(),
    }),
    // TODO tags?
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableScheduleFields}
          validationSchema={RecurringGoalScheduleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<CoreRecurringGoalScheduleTypeToSend>) => {
            const handleSourceFieldChange = (option: SingleValue<GenericOption>, setFieldValue: any) => {
              if (option && option.value) {
                const sourceField = getObjectFromListById(sourceFields, option.value);
                if (sourceField) {
                  setFieldValue('summaryTemplate', sourceField.name);
                } else {
                  console.error('handleSourceFieldChange encountered bad option lookup: ', option, sourceFields);
                }
              }
            };
            const unitOfMeasureElement = getUnitOfMeasureElement(formikProps, sourceFields);
            const unitOfMeasureLabel = getUnitOfMeasureLabel(formikProps, sourceFields);
            const unitOfMeasure = getUnitOfMeasure(formikProps, sourceFields);
            const betterDirection = getBetterDirection(formikProps, sourceFields);
            let goalValueLabel = 'Goal Value';
            let cutoffLabel = 'Partial Credit Start/Limit';
            if (betterDirection) {
              goalValueLabel = goalValueLabel += ' (' + betterDirection.toLowerCase() + ' is better)';
              if (betterDirection.toLowerCase() === 'higher') {
                cutoffLabel = 'Partial Credit Starting Point';
              } else if (betterDirection.toLowerCase() === 'lower') {
                cutoffLabel = 'Partial Credit Limit';
              }
            }

            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{getModalTitle(mode)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Only show createdByText on editing */}
                  {createdByText}
                  <FormikController control='select' name='frequency' label='Frequency' options={FREQUENCY_OPTIONS} />
                  <FormikController
                    control='date'
                    name='startDateTime'
                    label='Start Date (Recurs on this day)'
                    timezone={HQ_TIMEZONE}
                  />
                  <FormikController
                    control='select'
                    name='sourceField'
                    label='Data Source'
                    options={sourceFieldOptions}
                    onChange={handleSourceFieldChange}
                  />
                  <FormikController control='input' name='summaryTemplate' label='Summary' />
                  <FormikController
                    control='select'
                    name='fieldCoreProcess'
                    label='Field Core Process'
                    options={fieldCoreProcessOptions}
                  />
                  <FormikController control='input' name='linkUrlTemplate' label='Link (optional)' />
                  <FormikController control='input' name='descriptionTemplate' label='Description (optional)' />
                  <FormikController control='input' name='suggestedStaff' label='Suggested Staff (optional)' />
                  <FormikController control='input' name='totalPoints' label='Weight' />
                  <FormikController
                    control='select'
                    name='scoringType'
                    label='Scoring Type'
                    options={SCORING_TYPE_OPTIONS}
                  />
                  <div className='d-flex align-items-center'>
                    <FormikController className='flex-grow-1' control='input' name='goalValue' label={goalValueLabel} />
                    {unitOfMeasureElement}
                  </div>
                  <Row>
                    <Col md={2} />
                    <Col>
                      <FormikController control='checkbox' name='allowAutoPush' label='Allow Auto Push' />
                      {formikProps.values.allowAutoPush && (
                        <>
                          <div className='d-flex align-items-center'>
                            <FormikController
                              className='flex-grow-1'
                              control='input'
                              name='autoPushExceedByDeltaValue'
                              label='When goal is exceeded by '
                            />
                            {unitOfMeasureElement}
                          </div>
                          <div className='d-flex align-items-center'>
                            <FormikController
                              className='flex-grow-1'
                              control='input'
                              name='autoPushChangeDeltaValue'
                              label="Then set next month's goal to be "
                            />
                            <span className={styles.verticallyCenteredToInput}>
                              {unitOfMeasureLabel} {betterDirection} than last months goal
                            </span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <FormikController
                              className='flex-grow-1'
                              control='input'
                              name='autoPushBestExpectedValue'
                              label='It will continue to increase until it reaches '
                            />
                            {unitOfMeasureElement}
                          </div>
                          <p className={styles.autoPushDescription}>
                            The goal will initially start at
                            <span className={styles.autoPushDescriptionValue}>
                              {' ' + formikProps.values.goalValue} {unitOfMeasureLabel}
                            </span>
                            .
                            <br />
                            If the team gets a result of {betterDirection} than
                            <span className={styles.autoPushDescriptionValue}>
                              {' ' + getAutoPushExceedByAbsoluteValue(formikProps.values, betterDirection)}{' '}
                              {unitOfMeasureLabel}
                            </span>
                            <br />
                            Then the next goal will be set to
                            <span className={styles.autoPushDescriptionValue}>
                              {' ' + getAutoPushChangeAbsoluteValue(formikProps.values, betterDirection) + ' '}
                              {unitOfMeasureLabel}
                            </span>
                            <br />
                            This will continue{' '}
                            {formikProps.values.autoPushBestExpectedValue
                              ? 'until the goal reaches '
                              : 'until goals can\'t be reached'}
                            {formikProps.values.autoPushBestExpectedValue && (
                              <span className={styles.autoPushDescriptionValue}>
                                {' ' + formikProps.values.autoPushBestExpectedValue + ' '}
                                {unitOfMeasureLabel}
                              </span>
                            )}
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                  {formikProps.values.scoringType === LINEAR_FROM_CUTOFF && (
                    <>
                      <div className='d-flex align-items-center'>
                        <FormikController
                          className='flex-grow-1'
                          control='input'
                          name='cutoffValue'
                          label={cutoffLabel}
                        />
                        {unitOfMeasureElement}
                      </div>
                      {formikProps.values.allowAutoPush && (
                        <Row>
                          <Col md={2} />
                          <Col>
                            <p className={styles.autoPushDescription}>{cutoffLabel} is not effected by auto-push</p>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  <div style={{ height: 100 }}>
                    <GoalScoringVisualLineGraph
                      goalScheduleToGraph={formikProps.values}
                      betterDirection={betterDirection}
                      unitOfMeasure={unitOfMeasure}
                    />
                  </div>
                  <FormikController control='checkbox' name='onNcr'
                                    label='On NCR (in OD for informational purposes only)' />
                  <FormikController control='checkbox' name='onSmr'
                                    label='On SMR (in OD for informational purposes only)' />
                  <FormikController control='select' name='planningToAuto'
                                    label='Planning to be auto-scored from SL?' options={PLANNING_TO_AUTO_OPTIONS} />
                  {/* 'startingOptions' because we are allowing the creation of new tags */}
                  {/*<FormikController control="tags" name="tags" label="Tags" startingOptions={tagOptions}/>*/}
                </Modal.Body>
                <Modal.Footer>
                  {/* Only show delete button on editing */}
                  {deleteButton}
                  <Button variant='secondary' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant='primary' type='submit' disabled={formikProps.isSubmitting}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyCoreRecurringGoalScheduleDialogOk(
  modifiedSchedule: CoreRecurringGoalScheduleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchSchedules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  // TODO I don't like this it is hacky
  const modifiedScheduleToSend = convertToTypeToSend(modifiedSchedule, modifiedSchedule._unitOfMeasure);
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating schedule ' + idToUpdate, modifiedScheduleToSend);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios
      .patch(CORE_RECURRING_GOAL_SCHEDULES_URL + '/' + idToUpdate + '/', modifiedScheduleToSend)
      .then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'change', idToUpdate),
          status: 'success',
          messageBody: <span>Recurring goal schedule updated successfully.</span>,
        });
        refetchSchedules();
      });
  } else {
    console.log('Creating schedule', modifiedScheduleToSend);
    if (modifiedScheduleToSend.id) {
      console.error('Creating a schedule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a schedule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(CORE_RECURRING_GOAL_SCHEDULES_URL + '/', modifiedScheduleToSend).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('recurringTaskSchedule', 'create'),
          status: 'success',
          messageBody: <span>Recurring goal schedule created successfully.</span>,
        });
        refetchSchedules();
      });
    }
  }
  return promise;
}

function getAutoPushChangeAbsoluteValue(values: CoreRecurringGoalScheduleTypeToSend, betterDirection?: string) {
  if (values.goalValue && values.autoPushChangeDeltaValue && betterDirection) {
    const changeDeltaNumber = Number(values.autoPushChangeDeltaValue);
    const goalValueNumber = Number(values.goalValue);
    if (betterDirection && betterDirection.toLowerCase() === 'higher') {
      return goalValueNumber + changeDeltaNumber;
    } else if (betterDirection && betterDirection.toLowerCase() === 'lower') {
      return goalValueNumber - changeDeltaNumber;
    } else {
      return '???';
    }
  }
  return '???';
}

function getAutoPushExceedByAbsoluteValue(values: CoreRecurringGoalScheduleTypeToSend, betterDirection?: string) {
  if (values.goalValue && values.autoPushExceedByDeltaValue && betterDirection) {
    const exceedByDeltaNumber = Number(values.autoPushExceedByDeltaValue);
    const goalValueNumber = Number(values.goalValue);
    if (betterDirection && betterDirection.toLowerCase() === 'higher') {
      return goalValueNumber + exceedByDeltaNumber;
    } else if (betterDirection && betterDirection.toLowerCase() === 'lower') {
      return goalValueNumber - exceedByDeltaNumber;
    } else {
      return '???';
    }
  }
  return '???';
}

export default ModifyCoreRecurringGoalScheduleDialog;
export {
  defaultHandleModifyCoreRecurringGoalScheduleDialogOk,
  LINEAR_FROM_CUTOFF,
  ALL_OR_NOTHING,
  SCORING_TYPE_OPTIONS,
  PERCENT_UOM,
};
