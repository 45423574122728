import axios, { AxiosRequestConfig } from 'axios';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { getSimpleModal, ModifyDialogState } from '../../../../../helpers/GridComponentHelpers';
import CoreTriggerRuleType, { CoreTriggerRuleTypeToSend } from '../../../../../domain/coreTriggerRule';
import { Mode } from '../../../../../state_types/mode';
import { CORE_TRIGGER_RULE_URL, EVENT_TYPES_URL, GET_TAGS_URL } from '../../../../../constants/urls';
import TagType from '../../../../../domain/tag';
import FormikController from '../../../../../formik/FormikSelect/FormikController';
import EventTypeType from '../../../../../domain/eventType';
import { createFeedbackMessageKey, FeedbackMessage } from '../../../../FeedbackMessages/FeedbackMessages';
import { getNameOfCreationUser } from '../../../../../helpers/AuditFieldHelper';
import React from 'react';
import FieldCoreProcessType from '../../../../../domain/fieldCoreProcess';
import { getFormikReadOnlyField } from '../../../../../formik/FormikHelpers';
import { useGetFpcs } from '../../../apis/fcp_api';
import { DEADLINE_FLEXIBILITY_OPTIONS, PLANNING_TO_AUTO_OPTIONS } from '../../../../../constants/globals';

export interface ModifyCoreTriggerRuleDialogProps {
  fieldCoreProcess?: FieldCoreProcessType;
  state: ModifyDialogState<CoreTriggerRuleType>;
  onOk: (updatedRuleFields: CoreTriggerRuleTypeToSend, idToUpdate: number | null) => Promise<void>;
  onCancel: () => void;
  onDeleteIcon: (ruleToDelete: CoreTriggerRuleType) => void;
}

const getMode = (state: ModifyDialogState<CoreTriggerRuleType>) => {
  if (state.objectToModify) {
    return Mode.Change;
  }
  return Mode.Add;
};

const getModalTitle = (mode: Mode) => {
  let titleAction;
  if (mode === Mode.Add) {
    titleAction = 'Add';
  } else {
    titleAction = 'Change';
  }
  return titleAction + ' Core Trigger Rule';
};

const ModifyCoreTriggerRuleDialog = (props: ModifyCoreTriggerRuleDialogProps) => {
  const { state, onOk, onCancel, onDeleteIcon } = props;
  // If task to edit is set, assume edit.
  // Otherwise, assume create
  const mode = getMode(state);

  const handleCancel = () => {
    onCancel();
  };
  // const task = state.taskToEdit;

  const tagsRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const eventTypeRequestConfig = {
    // No parameters
  } as AxiosRequestConfig;
  const {
    isLoading,
    error,
    data: optionsData,
    refetch: refetchOptions,
  } = useQuery(['allCoreTriggerRuleOptionsQuery', [eventTypeRequestConfig, tagsRequestConfig]], () => {
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios
    // I wish I could put TeamMemberType[] and TagType[] on the requests
    //  However, axios.all() typings assumes all response types match first request, so using 'any'
    const tagsRequest = axios.get<any>(GET_TAGS_URL, tagsRequestConfig);
    const eventTypeRequest = axios.get<any>(EVENT_TYPES_URL, eventTypeRequestConfig);
    return axios.all([tagsRequest, eventTypeRequest]).then(
      axios.spread((...responses) => {
        return {
          tagsResponse: responses[0],
          eventTypeResponse: responses[1],
        };
      }),
    );
  });
  const initialFcp = props.fieldCoreProcess;
  const { isLoading: isLoadingFpcs, error: fcpError, data: fcpData } = useGetFpcs(null);
  if (isLoading || isLoadingFpcs) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Loading...');
  }
  if (error || fcpError) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'Error!');
  }
  if (optionsData === undefined) {
    return getSimpleModal(state.isOpen, handleCancel, getModalTitle(mode), 'No Data!');
  }

  const tags = optionsData.tagsResponse.data as TagType[];

  const tagOptions = tags.map((tag) => {
    return {
      label: tag.name,
      // Value is the object itself. This makes it easy for Formik to pass to backend
      // TODO change to id?
      value: tag,
    };
  });

  const eventTypes = optionsData.eventTypeResponse.data as EventTypeType[];
  const eventTypeOptions = eventTypes.map((eventType) => {
    return {
      label: eventType.name,
      value: eventType.id,
    };
  });

  let fieldCoreProcessOptions: any[] = [];
  if (fcpData) {
    const fieldCoreProcesses = fcpData.data as FieldCoreProcessType[];
    fieldCoreProcessOptions = fieldCoreProcesses.map((fieldCoreProcess) => {
      const optionLabel = fieldCoreProcess.name;
      return {
        label: optionLabel,
        value: fieldCoreProcess.id,
      };
    });
  }

  // TODO should this be in some sort of useState hook?
  let initialStateOfEditableRuleFields;
  let onSubmit;
  let deleteButton: JSX.Element | null;
  let createdByText: JSX.Element | null;
  let fcp;
  if (mode === Mode.Change) {
    // Tell typescript that taskToEdit is definitely not null
    // TODO this is hacky, find a way to fix it
    const rule = state.objectToModify!;

    // Copy only editable fields
    initialStateOfEditableRuleFields = {
      createOnEventType: rule.createOnEventType?.id,

      daysToComplete: rule.daysToComplete,
      summaryTemplate: rule.summaryTemplate,
      descriptionTemplate: rule.descriptionTemplate,
      suggestedStaff: rule.suggestedStaff,
      linkUrlTemplate: rule.linkUrlTemplate,
      totalPoints: rule.totalPoints,
      onNcr: rule.onNcr,
      onSmr: rule.onSmr,
      planningToAuto: rule.planningToAuto,
      deadlineFlexibility: rule.deadlineFlexibility,

      tags: rule.tags,

      fieldCoreProcess: rule.fieldCoreProcess.id,
    } as CoreTriggerRuleTypeToSend;
    fcp = rule.fieldCoreProcess;
    const createdByName = getNameOfCreationUser(rule);
    createdByText = getFormikReadOnlyField('Created by:', createdByName);

    deleteButton = (
      <Button
        className='bi-trash-fill mr-auto'
        variant='danger'
        onClick={() => {
          onDeleteIcon(rule);
        }}
      >
        Delete
      </Button>
    );

    onSubmit = (updatedRule: CoreTriggerRuleTypeToSend | null, { setSubmitting }: any) => {
      console.log('update core rule dialog ok hit', updatedRule);
      setTimeout(() => {
        if (updatedRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('updatedRule was null, contact developers');
          setSubmitting(false);
          return;
        }

        // TODO make this typescript cast have a validation or something
        onOk(updatedRule, rule.id).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
  } else {
    // Create
    initialStateOfEditableRuleFields = {
      createOnEventType: undefined,

      daysToComplete: undefined,
      summaryTemplate: '',
      descriptionTemplate: '',
      suggestedStaff: '',
      linkUrlTemplate: '',
      totalPoints: undefined,
      planningToAuto: 'UNKNOWN',
      deadlineFlexibility: 'FLEXIBLE',
      onNcr: false,
      onSmr: false,
      tags: [],

      fieldCoreProcess: initialFcp?.id,
    } as CoreTriggerRuleTypeToSend;
    fcp = props.fieldCoreProcess;
    onSubmit = (newRule: CoreTriggerRuleTypeToSend, { setSubmitting }: any) => {
      setTimeout(() => {
        if (newRule === null) {
          // If this ever happens, we probably need to use 'useEffect' in some capacity
          alert('new rule was null, contact developers');
          setSubmitting(false);
          return;
        }
        // TODO make this typescript cast have a validation or something
        onOk(newRule, null).then(() => {
          // In case new tags were created
          refetchOptions();
          setSubmitting(false);
        });
      }, 400);
    };
    deleteButton = null;
    createdByText = null;
  }

  const autoAddedTags: string[] = [];
  if (fcp) {
    autoAddedTags.push(fcp.name);
  }

  const TriggerRuleFormSchema = Yup.object().shape({
    // createOnEventType: Yup.number().nullable().required('Required'),
    createOnEventType: Yup.number().nullable().optional(), // Temporarily optional until we have a better idea what these will be
    daysToComplete: Yup.number().required('Required'), // TODO make integer only
    summaryTemplate: Yup.string().required('Required'),
    descriptionTemplate: Yup.string().optional(),
    linkUrlTemplate: Yup.string().optional(),
    totalPoints: Yup.number().required('Required'), // TODO make integer only
    // TODO tags?
  });

  return (
    // This component causes 'findDOMNode is deprecated in StrictMode' warning
    // Unfortunately, this workaround on SO didn't work https://stackoverflow.com/a/64325602
    // The best way to fix this is to upgrade to react-bootstrap 2.x/bootstrap 5, but that takes some work
    // TODO fix this warning
    <Modal show={state.isOpen} onHide={handleCancel}>
      {
        <Formik
          initialValues={initialStateOfEditableRuleFields}
          validationSchema={TriggerRuleFormSchema}
          onSubmit={onSubmit}
        >
          {(formikProps: any) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(mode)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Only show createdByText on editing */}
                {createdByText}
                <FormikController
                  control='select'
                  name='createOnEventType'
                  label='Event Type'
                  options={eventTypeOptions}
                />

                <FormikController control='input' name='daysToComplete' label='Days To Complete' />
                <FormikController control='input' name='summaryTemplate' label='Summary' />
                <FormikController
                  control='select'
                  name='fieldCoreProcess'
                  label='Field Core Process'
                  options={fieldCoreProcessOptions}
                />
                <FormikController control='input' name='descriptionTemplate' label='Description (optional)' />
                <FormikController control='input' name='suggestedStaff' label='Suggested Staff (optional)' />
                <FormikController control='input' name='linkUrlTemplate' label='Link (optional)' />
                <FormikController control='input' name='totalPoints' label='Weight' />
                <FormikController control='checkbox' name='onNcr'
                                  label='On NCR (in OD for informational purposes only)' />
                <FormikController control='checkbox' name='onSmr'
                                  label='On SMR (in OD for informational purposes only)' />
                <FormikController control='select' name='planningToAuto'
                                  label='Planning to be auto-completed from SL?' options={PLANNING_TO_AUTO_OPTIONS} />
                <FormikController control='select' name='deadlineFlexibility'
                                  label='How rigid is the date of this deadline?' options={DEADLINE_FLEXIBILITY_OPTIONS} />
                {/* 'startingOptions' because we are allowing the creation of new tags */}
                <FormikController
                  control='tags'
                  name='tags'
                  label='Tags (optional)'
                  startingOptions={tagOptions}
                  automaticallyAdded={autoAddedTags}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* Only show delete button on editing */}
                {deleteButton}
                <Button variant='secondary' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant='primary' type='submit' disabled={formikProps.isSubmitting}>
                  Ok
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      }
    </Modal>
  );
};

function defaultHandleModifyCoreTriggerRuleDialogOk(
  modifiedRule: CoreTriggerRuleTypeToSend,
  idToUpdate: number | null,
  closeModifyDialog: () => void,
  refetchRules: () => void,
  addFeedbackMessage: (feedbackMessage: FeedbackMessage) => void,
) {
  let promise;
  if (idToUpdate !== null) {
    console.log('Updating rule ' + idToUpdate, modifiedRule);
    // POST is for new stuff, PUT is for replacing task (must have ALL fields)
    // This uses PATCH, which loads old task and only updates fields you passed
    // https://stackoverflow.com/a/24241955/13815107
    promise = axios.patch(CORE_TRIGGER_RULE_URL + '/' + idToUpdate + '/', modifiedRule).then((response) => {
      closeModifyDialog();
      addFeedbackMessage({
        key: createFeedbackMessageKey('triggerRule', 'change', idToUpdate),
        status: 'success',
        messageBody: <span>Trigger rule updated successfully.</span>,
      });
      refetchRules();
    });
  } else {
    console.log('Creating rule', modifiedRule);
    if (modifiedRule.id) {
      console.error('Creating a rule but sending an id. Was this meant to be a modify?');
      promise = Promise.reject('Creating a rule but sending an id. Was this meant to be a modify?');
    } else {
      promise = axios.post(CORE_TRIGGER_RULE_URL + '/', modifiedRule).then((response) => {
        closeModifyDialog();
        addFeedbackMessage({
          key: createFeedbackMessageKey('triggerRule', 'create'),
          status: 'success',
          messageBody: <span>Trigger rule created successfully.</span>,
        });
        refetchRules();
      });
    }
  }
  return promise;
}

export default ModifyCoreTriggerRuleDialog;
export { defaultHandleModifyCoreTriggerRuleDialogOk };
